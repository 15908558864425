import * as React from 'react';

const NotFoundPage = () => {
  return (
    <main>
      <h1 className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-800">
          Page 404
        </span>
      </h1>
    </main>
  );
};

export default NotFoundPage;
